<template>
  <v-container
    fluid
    tag="section"
  >
    <v-card flat>
      <v-menu offset-y>
        <template #activator="{on, attrs}">
          <v-btn
            absolute
            top
            right
            fab
            small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              fa-plus
            </v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, index) in leagues"
            :key="index"
            @click="add(item.value)"
          >
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-data-table
        :items="events"
        :headers="[
          { text: 'Beginn', value: 'startDate', sortable: false },
          { text: 'Liga', value: 'liga.shortName', sortable: false },
          { text: 'Mannschaften', value: 'teams', sortable: false },
          { text: 'Status', value: 'status', sortable: false, align: 'center' },
          { text: 'Hinweise', value: 'publicComment', sortable: false, align: 'center'},
          { text: 'Anmerkungen', value: 'internalComment', sortable: false, align: 'center'},
          { text: 'Halle', value: 'venue', sortable: false, align: 'center'},
          { text: 'Bearbeiten', value: 'edit', sortable: false, align: 'center' },
          { text: 'Löschen', value: 'delete', sortable: false, align: 'center' },
          { text: 'Live-Eingabe', value: 'input', sortable: false, align: 'center' },
          { text: 'Live-Anzeige', value: 'view', sortable: false, align: 'center' }
        ]"
        :mobile-breakpoint="0"
        :items-per-page="-1"
        hide-default-footer
        :item-class="(item) => ({'stbw2023bold': item.status === 'EventMovedOnline', 'stbw2023red': item.status === 'EventStarted'})"
      >
        <template #item.startDate="{item}">
          <i v-if="item.status === 'EventPostponed'"><span style="text-decoration:line-through;">{{ item.startDate | dateformat('DD.MM.YYYY') }}</span><br>wird verschoben,<br>Termin noch offen</i>
          <span v-else :inner-html.prop="item.startDate | dateformat('dddd<br>DD.MM.YYYY<br>HH:mm')" />
        </template>
        <template #item.teams="{item}">
          <div
            v-for="t in item.teams"
            :key="t.team._id"
          >{{ t.team.name }}</div>
        </template>
        <template #item.status="{item}">
          <base-edit-dialog-select
            :value="item.status"
            :items="status"
            :id="item._id"
            @input="({value, id}) => $emit('changestatus', {id, value})"
          />
        </template>
        <template #item.publicComment="{item}">
          <v-tooltip
            v-if="item.publicComment"
            bottom
          >
            <template
              v-slot:activator="{ on }"
            >
              <v-btn
                v-on="on"
                small
                fab
                text
              >
                <v-icon>
                  far fa-info-circle
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ item.publicComment }}
            </span>
          </v-tooltip>
        </template>
        <template #item.internalComment="{item}">
          <v-tooltip
            v-if="item.internalComment"
            bottom
          >
            <template
              v-slot:activator="{ on }"
            >
              <v-btn
                v-on="on"
                small
                fab
                text
              >
                <v-icon>
                  far fa-info-circle
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ item.internalComment }}
            </span>
          </v-tooltip>
        </template>
        <template #item.venue="{item}">
          <v-tooltip
            v-if="item.venue"
            bottom
          >
            <template
              v-slot:activator="{ on }"
            >
              <v-btn
                v-on="on"
                small
                fab
                text
                @click.stop="openmaps(item.venue.address)"
              >
                <v-icon>
                  far fa-circle-h
                </v-icon>
              </v-btn>
            </template>
            <span>
                {{ item.venue.name }}<br>
                {{ item.venue.address.streetAddress }} {{ item.venue.address.streetNumber }}<br>
                {{ item.venue.address.postalCode }} {{ item.venue.address.addressLocality }}
              </span>
          </v-tooltip>
        </template>
        <template #item.edit="{item}">
          <v-btn
            text
            small
            fab
            @click.stop="edit(item._id)"
          >
            <v-icon>
              far fa-pencil
            </v-icon>
          </v-btn>
        </template>
        <template #item.delete="{item}">
          <v-btn
            text
            small
            fab
            @click.stop="del(item._id)"
          >
            <v-icon>
              far fa-trash-alt
            </v-icon>
          </v-btn>
        </template>
        <template #item.input="{item}">
          <v-btn
            text
            fab
            small
            @click.stop="(ev) => input(item._id, ev)"
          >
            <span class="fa-stack fa-1x">
              <i
                class="far fa-play fa-stack-2x"
                style="margin-left:0px;margin-top:-6px;font-size:300%;"
              />
              <i
                class="far fa-pencil-alt fa-stack-1x"
                style="margin-left:4px;margin-top:1px;font-size:100%;text-shadow:0 0 2px white;"
              />
            </span>
          </v-btn>
        </template>
        <template #item.view="{item}">
          <v-btn
            text
            fab
            small
            @click.stop="(ev) => view(item._id)"
          >
            <span class="fa-stack fa-1x">
              <i
                class="far fa-play fa-stack-2x"
                style="margin-left:0px;margin-top:-5px;font-size:300%;"
              />
              <i
                class="far fa-eye fa-stack-1x"
                style="margin-left:4px;font-size:100%;text-shadow:0 0 2px white;"
              />
            </span>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <edit-event v-model="dialog.open" :id="dialog.id" />
  </v-container>
</template>

<script>
import { deleteQuery, useGraphQL } from '@/plugins/graphql'
import { EventStatus, StbW2023WkMode } from '@/enum'
import gql from 'graphql-tag'
import moment from 'moment'

const query = `
  _id
  identifiers { name value }
  type
  ... on StbW2023League {
    name shortName order
    teams {
      team { _id name }
    }
    events {
      _id
      name startDate publicComment status mode
      teams {
        team { _id name club { _id name } }
      }
      venue { _id name address { streetAddress streetNumber postalCode addressLocality } }
    }
  }
`
export default {
  name: 'events',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  components: {
    EditEvent: () => import('../dialogs/EditEvent.vue')
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    EventFind: [],
    filter: '',
    date: [0, 0],
    dialog: {
      open: false,
      id: null
    }
  }),

  computed: {
    modes () {
      return StbW2023WkMode
    },
    status () {
      return EventStatus
    },
    leagues () {
      return this.EventFind.map(l => ({
        value: l._id,
        text: l.name,
        order: l.order
      })).sort((a, b) => a.order < b.order ? -1 : 1)
    },
    events () {
      return this.EventFind.reduce((acc, curr) => {
        acc.push(...(curr.events || []).map(e => ({
          ...e,
          liga: curr,
          mannschaften: e.teams?.slice().sort((a, b) => {
            if (a.home !== b.home) return a.home ? -1 : 1
            return a.order < b.order ? -1 : 1
          }).map(t => t.team.name) || []
          /* ergebnisse: e.teams?.slice().sort((a, b) => {
            if (a.home !== b.home) return a.home ? -1 : 1
            return a.order < b.order ? -1 : 1
          }).map(t => t.result) || [] */
        })))
        return acc
      }, []).sort((a, b) => {
        if (a.startDate !== b.startDate) return a.startDate < b.startDate ? -1 : 1
        if (a.liga.order !== b.liga.order) return a.liga.order < b.liga.order ? -1 : 1
      })
    }
  },

  methods: {
    add (league) {
      this.mutate({
        mutation: gql`
          mutation($parent: UUID!, $name: String!, $startDate: DateTime!, $mode: StbW2023WkMode!) {
            StbW2023WkCreate(parent: $parent, name: $name, startDate: $startDate, mode: $mode) { _id }
          }
        `,
        variables: {
          parent: league,
          name: '',
          startDate: moment().format('YYYY-MM-DD 00:00:00'),
          mode: 'wk_4aus5'
        }
      })
    },
    async del (id) {
      if (await this.$root.$children[0].$refs.confirm.open('Wirklich löschen?', 'Dieser Vorgang kann nicht rückgängig gemacht werden!', { width: 400 })) {
        this.mutate({
          mutation: gql`
          mutation($id: UUID!) {
            StbW2023WkDelete(id: $id)
          }
        `,
          variables: {
            id
          }
        })
      }
    },
    edit (id) {
      this.dialog.id = id
      this.dialog.open = true
    },
    input (id, ev) {
      this.$openlink(`/input/${id}`, ev)
    },
    view (id) {}
  },

  apollo: {
    EventFind: {
      query: gql`
          query($parent: UUID) { EventFind(parent: $parent) { ${query} }}
        `,
      variables () {
        return {
          parent: this.id
        }
      }
    },
    $subscribe: {
      EventUpdate: {
        query: gql`
          subscription($parent: UUID) { EventUpdate(parent: $parent) { ${query} }}
        `
      },
      EventDelete: {
        query: gql`
          subscription($parent: UUID) { EventDelete(parent: $parent) }
        `,
        result (id) {
          deleteQuery('EventFind', 'EventDelete', this.EventFind, id)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
